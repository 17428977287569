import React from "react";

function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", 
      }}
    >
      <h1 style={{ color: "#c3a069" }}>404 - Page Not Found</h1>
    </div>
  );
}

export default NotFound;
