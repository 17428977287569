import "./App.css";
import Home from "./Pages/home";
import About from "./Pages/about";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from "./Components/navbar";
import Footer from "./Components/footer";
import NotFound from "./Pages/notfound";
import Contact from "./Pages/contact";
import Services from "./Pages/services";
import Services_Details from "./Pages/service_details"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import Backend from 'i18next-http-backend';
import Collabration from "./Pages/collabration";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ar',
    detection:{
            order: [ 
            'cookie', 
            'localStorage', 
            'sessionStorage', 
            'navigator', 
            'htmlTag', 
            'path', 
            'subdomain'],
            caches: ['cookie'],
      
          },
    lng: Cookies.get('i18next') || 'ar',
    interpolation: {
      escapeValue: false, 
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', 
    },
  });




function App() {
  const lng = useSelector((state) => state.langR.lang);

  return (
    <div dir={lng === 'ar' ? 'rtl' : 'ltr'}>
      <BrowserRouter>
      <div className="d-flex flex-column min-vh-100">
        <Navbar />
        <main className="flex-grow-1 content">
        <Switch>
          <Route component={Home} path="/" exact />
          <Route component={About} path="/about" exact />
          <Route component={About} path="/our_mission" exact />
          <Route component={About} path="/our_vision" exact />
          <Route component={Contact} path="/contact" exact />
          <Route component={Services} path="/services" exact />
          <Route component={Collabration} path="/our_clients" exact />
          <Route component={Services_Details} path="/ServicesDetails/:id"/> 
          <Route component={NotFound} path="*" />
        </Switch> 
         </main>
       
        <Footer />
        </div>
       
      </BrowserRouter>
    </div>
  );
}

export default App;
