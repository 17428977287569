import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { changeLanguageAction } from "../Store/Actions/changeLanguageAction";
import { NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const lng = Cookies.get('i18next') || 'ar'; 

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    Cookies.set('i18next', lang); 
    dispatch(changeLanguageAction(lang));
  };

  useEffect(() => {
    const storedLang = Cookies.get('i18next') || 'ar';
    i18n.changeLanguage(storedLang);
    dispatch(changeLanguageAction(storedLang));
  }, [i18n, dispatch]);

  const isActive = (paths) => {
    return paths.includes(location.pathname) ? 'activee' : '';
  };
  
  
  
  

  return (
    <nav className="navbar navbar-expand-lg main-menu "  
  style={{ backgroundColor: "#0c5531" }}     
    dir="ltr">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={`${process.env.PUBLIC_URL}/images/logo5.png`} alt="logo" className="img-fluid" style={{ width: "13rem", height: "5rem" }} />
        </Link>
        <button
          className="navbar-toggler" 
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" style={{ color: "#c3a069" }}></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
          <li className={`nav-item ${location.pathname === "/" ? 'activee' : ''}`}>
  <NavLink className="nav-link" to="/">{t("home_nav1")}</NavLink>
</li>

            <li>
            <NavDropdown 
                title={t("about_nav2")} 
                className={`nav-item ${isActive(["/about", "/our_vision", "/mission"])}`} 
                id="basic-nav-dropdown" >
                <NavDropdown.Item as={NavLink} to="/about" className={({isActive }) => `${isActive ? 'activee ' : ''}`} >
                  {t("about_nav2")}
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/our_vision"  className={({ isActive }) => `${isActive ? 'activee' : ''}`}>
                  {t("vision")}
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/our_mission" className={({ isActive }) => `${isActive ? 'activee' : ''}`}>
                  {t("mission")}
                </NavDropdown.Item>
              </NavDropdown>
            </li>
            <li  className={`nav-item ${isActive(["/services", "/ServicesDetails/:id"])}`} >
              <Link className="nav-link" to="/services">{t("books_nav3")}</Link>
            </li>
            <li className={`nav-item ${location.pathname === "/our_clients" ? 'activee' : ''}`}>
              <Link className="nav-link" to="/our_clients">{t("collabration_nav4")}</Link>
            </li>
            <li className={`nav-item ${location.pathname === "/contact" ? 'activee' : ''}`}>
              <Link className="nav-link" to="/contact">{t("contact_nav5")}</Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="languageDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
          
              >
                <img
                  src={lng === "ar" ? "https://cdn.weglot.com/flags/rectangle_mat/sa.svg" : "https://cdn.weglot.com/flags/rectangle_mat/us.svg"}
                  width="30"
                  height="20"
                  alt="Language flag"
                  className="mr-2"
                />
                {lng === "ar" ? "العربية" : "English"}
              </a>
              <div className="dropdown-menu" aria-labelledby="languageDropdown">
                <button className="dropdown-item" onClick={() => changeLanguage("ar")}>
                  <img
                    src="https://cdn.weglot.com/flags/rectangle_mat/sa.svg"
                    width="30"
                    height="20"
                    alt="Arabic flag"
                    className="mr-2"
                  />
                  العربية
                </button>
                <button className="dropdown-item" onClick={() => changeLanguage("en")}>
                  <img
                    src="https://cdn.weglot.com/flags/rectangle_mat/us.svg"
                    width="30"
                    height="20"
                    alt="United States flag"
                    className="mr-2"
                  />
                  English
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
