import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import WOW from 'wowjs';
import Header from "../Components/header";

function Collabration() {
  const { t } = useTranslation();
  const lng = useSelector((state) => state.langR.lang) || 'ar';

  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  const services = [
    { img: 'images/HICOO.png', text: 'HICOO' },
    { img: 'images/SAUDI_GAMS.png', text: 'SAUDI GAMS' },
    { img: 'images/SAUDI_Olympic_Academy.png', text: 'SAUDI Olympic Academy' },
    { img: 'images/BAWADR.png', text: 'BAWADR' },
    { img: 'images/Taiba_INVESTMENT.png', text: 'Taiba INVESTMENT' },
    { img: 'images/GULF_INTERNATIONAL_GROUP.png', text: 'GULF INTERNATIONAL GROUP' },
    { img: 'images/aitco.png', text: 'aitco' },
    { img: 'images/ALTALA_ALWOULI.png', text: 'ALTALA ALWOULI' },
    { img: 'images/SAUDI_BODYBUILDING_FEDERATION.png', text: 'SAUDI BODYBUILDING FEDERATION' },
    { img: 'images/مؤسسه_الموارد_الوطنيه.png', text: 'مؤسسه الموارد الوطنيه' },
    { img: 'images/ALMAWARED_ALULA_CO.png', text: 'ALMAWARED ALULA CO' },
    { img: 'images/TMOH_ALGAD_TRADING_COMPANY.png', text: 'TMOH ALGAD TRADING COMPANY' },
  ];

  const imgSize = 130; 

  return (
    <>
    {/* <Header content={t("collabration_nav4")} /> */}
     <div className="wow fadeIn bg-light" data-wow-delay="300ms" data-wow-duration="1500ms" style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>  
      <div style={{ position: 'relative', width: '800px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}> 
        <div
          className="text-center custom-text-color"
          style={{
            zIndex: 1,
            margin: '20px 0', 
          }}
        >
          <Header content={t("collabration_nav4")} /> 
          <h4 style={{ margin: 0, fontSize: '2rem', color: '#333',color:"#c3a069" }}>{t("clients")}</h4>
        </div>

        
        <div className='mt-5 wow fadeDown'data-wow-delay="500ms" data-wow-duration="1500ms" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', width: '100%', position: 'relative' }}>
          {services.map((service, index) => (
            <div key={index} style={{ width: `${imgSize}px`, height: `${imgSize}px`, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
              <img
                src={service.img}
                alt={service.text}
                // className="rounded-circle"
                style={{ 
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'contain', 
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
}

export default Collabration;
