import { createStore } from "redux";
import combineReducers from "./Reducers/combineReducers";
import Cookies from "js-cookie";


const initialLanguage = Cookies.get("i18next") || 'ar'; 

const store = createStore(combineReducers, {
    langR: { lang: initialLanguage }
});

export default store;


