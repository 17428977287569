import React from "react";
import Header from "../Components/header.js";
import ServicesComponent from "../Components/services.js";
import { useTranslation } from "react-i18next";
import WOW from 'wowjs';
import { useEffect } from "react";

const Services = () => {
    useEffect(() => {
		new WOW.WOW().init();
	}, []);
    const { t } = useTranslation()


    return(
        <>
         {/* <Header content={t("books_nav3")} /> 
         <h4 className="text-center mb-5 custom-text-color  wow fadeIn" data-wow-delay="300ms" data-wow-duration="1500ms">{t('service_title')}</h4> */}
         <ServicesComponent/>
        
        
        </>
    )

}


export default Services;
