import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import WOW from 'wowjs';

function Mission() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  const { t } = useTranslation();

  return (
<>
        <div className="col-md-6">
          <div className="d-flex align-items-start mb-4">
            <img src="images/mission.png" alt="رسالتنا" className="img-fluid rounded me-3 ml-4" style={{ width: "10rem", height: "6rem" }} />
            <div>
              <h2 style={{color:"#c3a069"}}>{t("mission")}</h2>
              <p className="p-3 rounded text-light"style={{backgroundColor: "#0c5531"}}>{t("mission_message")}</p>
            </div>
          </div>
        </div>
       </>
   
  );
}

export default Mission;
