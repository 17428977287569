import React from "react";
import Header from "../Components/header.js";
import AboutComponent from "../Components/about.js";
import { useTranslation } from "react-i18next";
import WOW from 'wowjs';
import { useEffect } from "react";
const About = () => {
  useEffect(() => {
		new WOW.WOW().init();
	}, []);
  const { t } = useTranslation();

  return (
    <>
      <Header content={t("about_nav2")} />
      <h4 className="text-center custom-text-color mb-5  wow fadeIn" data-wow-delay="300ms" data-wow-duration="1500ms">{t("about_title")}</h4>
     <AboutComponent/>
    </>
  );
};

export default About;
