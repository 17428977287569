import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import WOW from 'wowjs';
import Mission from "../Components/mission.js";
import Vision from "../Components/vision.js";
function About() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  const { t } = useTranslation();

  return (
    <div className="container">
    <div className="about my-5">
      <div className="row my-4 " data-wow-delay="300ms" data-wow-duration="1500ms">
        <div className="col-md-6 d-flex justify-content-center wow fadeInLeft">
          <img src="images/company1.png" alt="من نحن" className="img-fluid rounded" style={{ maxHeight: "20rem",width: "28rem", objectFit: "cover" }} />
        </div>
      
  <div className="col-md-6 d-flex align-items-center wow fadeInRight rounded" style={{ backgroundColor: "#0c5531" }}  >
          
          <p 
          // style={{color:"#c3a069"}}
          // style={{color:"#FFB22C"}}
          className="text-light"

          >
            {t("about_page")}
          </p>

        </div>
      </div>
    </div>
    <div className="row wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
    <Mission/>
     <Vision/>
     </div>
    </div>
  );
}

export default About;
