import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "./header.js";
import Card from "react-bootstrap/Card";
import WOW from "wowjs";
import { Row, Col } from "react-bootstrap";

function Services() {
  const { t } = useTranslation();
  const lng = useSelector((state) => state.langR.lang) || "ar";
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(
          lng === "ar" ? "./Arabic.json" : "./English.json"
        );
        const data = await response.json();
        setServices(data);
        console.log(data); 
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, [lng]);

  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <>
      <Header content={t("books_nav3")} />
      <h4
        className="text-center custom-text-color mb-5 wow fadeIn"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
      >
        {t("service_title")}
      </h4>
      <div className="mb-1 wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
        <div className="container mt-2" dir="ltr">
          <Row className="d-flex justify-content-center">
            {services.map((service, idx) => (
              <Col md={4} key={idx} className="d-flex justify-content-center">
                <Card className="service-card shadow-lg rounded wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <Card.Img variant="top" src={service.img} alt={service.title} className="service-img" />
                  <Card.Body dir={lng === "ar" ? "rtl" : "ltr"}  style={{ backgroundColor: "#0c5531", color: "white" }}>
                    <Card.Title className="mt-2">
                      <Link to={`/ServicesDetails/${service.id}`}  style={{ fontSize: '1.25rem', color: "white", fontWeight: 'bold', textDecoration: 'none' }}>
                        {service.title}
                      </Link>
                    </Card.Title>
                    <Card.Text style={{ fontSize: '1rem', marginTop: '10px', color: "white" }}>
                      {service.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
}

export default Services;
