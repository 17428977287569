import { combineReducers } from 'redux';
import languageReducer from './changeLanguageReducer';

export default combineReducers({
    langR: languageReducer,
});

export const changeLanguageAction = (payload) => ({
    type: 'CHANG_LANG',
    payload,
});
