import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from './header.js';
import Marquee from 'react-fast-marquee';
import Card from 'react-bootstrap/Card';
import WOW from 'wowjs';

function OurBookHome() {
  const { t } = useTranslation();
  const lng = useSelector((state) => state.langR.lang) || 'ar'; 
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await fetch(lng === 'ar' ? './Arabic.json' : './English.json');
        const data = await response.json();
        setBooks(data);
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };

    fetchBooks();
  }, [lng]);

  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  const services = [
    { img: 'images/HICOO.png'},
    { img: 'images/SAUDI_GAMS.png'},
    { img: 'images/SAUDI_Olympic_Academy.png'},
    { img: 'images/BAWADR.png' },
    { img: 'images/Taiba_INVESTMENT.png'},
    { img: 'images/GULF_INTERNATIONAL_GROUP.png' },
    { img: 'images/aitco.png'},
    { img: 'images/ALTALA_ALWOULI.png'},
    { img: 'images/SAUDI_BODYBUILDING_FEDERATION.png'},
    { img: 'images/مؤسسه_الموارد_الوطنيه.png' },
    { img: 'images/ALMAWARED_ALULA_CO.png' },
    { img: 'images/TMOH_ALGAD_TRADING_COMPANY.png'},
  ];
  return (
    <div className='p-3'>

     <Header content={t("collabration_nav4")} />
      <h4 className="container text-center custom-text-color mb-5  wow fadeIn" data-wow-delay="300ms" data-wow-duration="1500ms">{t("clients")}</h4> 
      <div className="mb-1 wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
        <div className="container mt-5 rounded-circle" dir="ltr">
        <Marquee pauseOnHover  direction={lng=== "ar" ? "right" : "left"}>
        {services.map((service, index) => (
          <Card className="mx-3 rounded-circle d-flex justify-content-center bg-white"  style={{ width: "12rem", height: "12rem"}}>
            <Card.Img variant="top" className="mx-auto"
            src={service.img} 
            style={{ width: "10rem", height: "10rem",objectFit: 'contain'
            }}/>
         </Card>

        )
      )}
        </Marquee>          
        </div>
      </div>
    </div>
  );
}

export default OurBookHome;

