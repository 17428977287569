import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import WOW from "wowjs";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
function Contact() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  // const [phone_nu, setPhoneNu] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: name,
      email: email,
      // phone_nu: phone_nu,
      subject: subject,
      message: message,
    };

    emailjs
      .send(
        'service_l9kxkno',
        'template_vljcfot',
        templateParams,
        'I0zsEQMwN5jr0Elu5',
      )
      .then((response) => {
        console.log("Email sent successfully", response.status, response.text);
        toast.success(t("send_success"));
      })
      .catch((error) => {
        console.log("Failed to send email", error);
        toast.error(t("send_error"));
      });
  };

  return (
    <div className="Contact contact-page" style={{backgroundColor:"#0c5531"}}>
      <div className="container p-5">
        <div className="row justify-content-center mt-5">
          <div className="col-md-7">
            <form
              onSubmit={handleSubmit}
              className=" wow fadeInLeft"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="row">
                <div className="col-sm-12 col-md-6 mb-3">
                  <input
                    className="form-control form-control-lg"
                    style={{color:"#c3a069"}}
                    placeholder={t("name")}
                    name="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                  <input
                    className="form-control form-control-lg"
                    placeholder={t("email")}
                    style={{color:"#c3a069"}}
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                {/* <div className="col-sm-12 col-md-6 mb-3">
                  <input
                    className="form-control form-control-lg"
                    placeholder={t("phone_number")}
                    name="phone_nu"
                    type="text"
                    value={phone_nu}
                    onChange={(e) => setPhoneNu(e.target.value)}
                  />
                </div> */}
                <div className="col-sm-12 col-md-12 mb-3">
                  <input
                    className="form-control form-control-lg"
                    placeholder={t("subject")}
                    style={{color:"#c3a069"}}
                    name="subject"
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-12 mb-3">
                  <textarea
                    className="form-control form-control-lg"
                    name="message"
                    style={{color:"#c3a069"}}
                    placeholder={t("message")}
                    rows="5"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                </div>
                <div className="col-sm-12 text-center">
                  <button className="send-btn rounded" type="submit">
                    {t("send")}
                  </button>
                  <Toaster />
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-1 mt-5 "></div>
          <div
            className="col-md-4 wow fadeInRight"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <div
              className="info-box p-3 mb-3 rounded"
              style={{ backgroundColor: "#c3a069", color: "#fff" }}
            >
              <h5>{t("address")}</h5>
              <p className="mt-2">
                <a
                  href="mailto:info@nsqevents.com"
                  style={{ color: "#fff", textDecoration: "none" }}
                  className="contact"
                >
                  info@nsqevents.com
                </a>
              </p>
              {/* <p
                className="mt-2"
                style={{
                  direction: "ltr",
                  textAlign: i18n.language === "ar" ? "right" : "left",
                }}
              >
                <a href="tel:+46720215670" className="contact" style={{ color: "#fff" }}>
                +966 56 666 9662
                </a>
              </p> */}
            </div>
            {/* <div
              className="info-box p-3 mb-3 rounded mt-5"
              style={{ backgroundColor: "#664E3F", color: "#fff" }}
            >
              <h5>{t("phone_number")}</h5>
              <p
                className="mt-2"
                style={{
                  direction: "ltr",
                  textAlign: i18n.language === "ar" ? "right" : "left",
                }}
              >
                <a href="tel:+46720215670" style={{ color: "#fff" }}>
                +966 56 666 9662
                </a>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
