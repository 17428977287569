import React from "react";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ContactComponent from "../Components/contact.js";
import Header from "../Components/header.js";
import AboutComponent from "../Components/about.js";
import ServicesComponent from "../Components/services.js";
import OurClients from "../Components/our_clients.js";
import WOW from 'wowjs';
import { useEffect } from "react";

const Home = () => {

  useEffect(() => {
		new WOW.WOW().init();
	}, []);
  const { t } = useTranslation();

  return (
    <>

<div style={{ position: "relative" }}>
  <img
    className="d-block w-100"
    src="images/header.png"
    alt="First slide"
    style={{
      width: "100%",          
      minHeight: "25rem",      
      objectFit: "cover",      
      objectPosition: "top",   
    }}
  />
  
  <div
    style={{
      position: "absolute",
      bottom: "100px",
      left: "50px",
      color: "white",
      padding: "20px",
      borderRadius: "5px",
    }}
  >
    <h2 className="wow fadeInUp fw-bolder text-light" data-wow-delay="300ms" data-wow-duration="1500ms">
      {t("first-sentence1")}
    </h2>
    <h4 className="wow fadeInLeft text-light" data-wow-delay="350ms" data-wow-duration="1500ms">
      {t("first-sentence2")}
    </h4>
    <h2 className="wow fadeInDown fw-bolder text-light" data-wow-delay="400ms" data-wow-duration="1500ms">
      {t("first-sentence3")}
    </h2>
  </div>
</div>

      {/* <div style={{ position: "relative" }}>
        <img
          className="d-block w-100"
          src="images/header.png"
          alt="First slide"
          style={{
            height: "39rem",
            objectFit: "cover", 
            objectPosition: "top",  
          }}
        />

        <div
          style={{
            position: "absolute",
            bottom: "100px",
            left: "50px",
            color: "white", 
            padding: "20px",
            borderRadius: "5px",  
          }}
        >
        <h2 className="wow fadeInUp fw-bolder text-light" data-wow-delay="300ms" data-wow-duration="1500ms" >
          {t("first-sentence1")}
        </h2>
        <h4 className="wow fadeInLeft text-light" data-wow-delay="350ms" data-wow-duration="1500ms" >
          {t("first-sentence2")}
        </h4>
        <h2 className="wow fadeInDown fw-bolder text-light" data-wow-delay="400ms" data-wow-duration="1500ms">
          {t("first-sentence3")}
        </h2>
        </div>
      </div> */}
      <Header content={t("about")}/>
      <h4 className="text-center custom-text-color mb-5   wow fadeIn" data-wow-delay="300ms" data-wow-duration="1500ms">{t("about_title")}</h4>
     <AboutComponent/>
      <ServicesComponent className='mb-5'/>
      <OurClients/> 
      <Header content={t("contact")} />
      <h4 className="text-center custom-text-color mb-5  wow fadeIn" data-wow-delay="300ms" data-wow-duration="1500ms">{t('contact_title')}</h4>
      <ContactComponent/>
    </>
  );
};

export default Home;
