import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import WOW from 'wowjs';


const ServiceDetails = () => {
  useEffect(() => {
		new WOW.WOW().init();
	}, []);
  const { id } = useParams();
  const { t } = useTranslation();
  const lng = useSelector((state) => state.langR.lang);
  const [service, setService] = useState(null);
  const [activeKey, setActiveKey] = useState('description');


  useEffect(() => {
    const file = lng === "ar" ? "/Arabic.json" : "/English.json";

    fetch(file)
      .then((response) => response.json())
      .then((data) => data.find((s) => s.id === id))
      .then((service) => setService(service));
      console.log(service)
  }, [lng, id]);

  return (
    <div className="Library" 
    style={{backgroundColor:"whitesmoke"}}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-5 text-center wow fadeInLeft">
            {service && <img src={service.img} alt={service.title} className="img-fluid mb-2 rounded "
            style={{width:"28rem",height:"18rem"}}
         />}
          </div>
          <div className="col-md-7 wow fadeInRight rounded" style={{ backgroundColor: "#0c5531" }}>
            {service && (
              <div className="p-3">
                <h2 className="text-light">
                  {service.title} 
                </h2>
                 <h3 className="text-light fw-light fw-1">{service.description}</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;